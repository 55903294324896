// @ts-nocheck
import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";

export default function AllInOne() {
  return (
    <Container>
      <Lottie
        options={{
          animationData: require("../../../images/allInOne.json"),
        }}
        height={380}
        width={380}
      />
      <Text>
        Ganvent es la app todo en uno para emprendedores:{" "}
        <Highlight>
          Crea tu tienda, gestiona tu inventario, registra tus transacciones,
          trabaja en equipo, visualiza estadísticas y mucho más...
        </Highlight>
      </Text>
      <Text></Text>
    </Container>
  );
}

const Container = styled.div`
  width: calc(100vw - 600px);
  height: 100%;
  background-color: #00a680;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p`
  margin: 0px 80px;
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
`;

const Highlight = styled.span`
  color: #ffde59;
  font-weight: bold;
  font-size: 1.4rem;
`;
