// @ts-nocheck
import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";

export default function ClientsSupport() {
  return (
    <Container>
      <Lottie
        options={{
          animationData: require("../../../images/happySupport.json"),
        }}
        height={380}
        width={380}
      />
      <Text>
        Contamos con el respaldo{" "}
        <Highlight>
          de más de ¡40,000 emprendedores! que ya utilizan Ganvent en todo el
          mundo
        </Highlight>
      </Text>
      <Text></Text>
    </Container>
  );
}

const Container = styled.div`
  width: calc(100vw - 600px);
  height: 100%;
  background-color: #246bfd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p`
  margin: 0px 80px;
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
`;

const Highlight = styled.span`
  color: #ffde59;
  font-weight: bold;
  font-size: 1.4rem;
`;
